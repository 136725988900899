import email from './email.png'
import unilogo from './unilogo.png'
import expo from './Expo.png'
import MITLogo from './MIT Logo.png'
import phone from './phone.png'
import facebook_icon from './facebook_icon.png'
import twitter_icon from './twitter_icon.png'
import linkedin_icon from './linkedin_icon.png'
import td from './td.png'
import H1 from './H1.png'
import H2 from './H2.png'
import H3 from './H3.png'
import H4 from './H4.png'
import H5 from './H5.jpg'
import H6 from './H6.png'
import H7 from './H7.png'
import H8 from './H8.jpg'
import H9 from './H9.png'
import H10 from './H10.jpg'
import H11 from './H11.png'
import td2 from './td2.png'
import up from './up.png'
import admin from './admin.png'

export const  assest = {
    expo,MITLogo,unilogo,email,phone,facebook_icon,twitter_icon,linkedin_icon,td,H1,H2,H3,td2,up,admin,H5,H4,H6,H7,H8,H9,H10,H11
}

