import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { useArticles } from '../Contex/ArticlesContext';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Header.css'; // Add custom CSS for styling

function Header() {
  const { getArticlesBySection } = useArticles(); // Get function from context
  const topPosts = getArticlesBySection('top'); // Grab articles for the "top" section

  return (
    <Container fluid className="p-0">
      <Carousel className="header-carousel" interval={2000} controls={true} indicators={true}>
        {topPosts.map((post, index) => (
          <Carousel.Item key={index}>
             <Link to={`/article/${post.id}`} >
                  
               
            <img
              className="d-block w-100 carousel-image"
              src={post.imageUrl}
              alt={`Slide ${index + 1}`}
            />
             </Link>
            <Carousel.Caption className="carousel-caption">
              <span className="badge bg-warning text-dark me-2">{post.category}</span>
              <span className="badge bg-danger">Trending</span>
              <h2 className="mt-2">
                {/* Wrap the title with a Link */}
                <Link to={`/article/${post.id}`} className="text-white text-decoration-none">
                  {post.title}
                </Link>
              </h2>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
}

export default Header;
