import React, { createContext, useContext, useState } from 'react';
import { assest } from '../../assest/assest'; // Import the assets (like images)

// Create a Context for Articles
const ArticlesContext = createContext();

// Provider component that will wrap the app
export const ArticlesProvider = ({ children }) => {
  // State to manage all articles
  const [articles, setArticles] = useState({
    allArticles: [
      {
        id: 1,
        category: 'Lifestyle',
        title: 'Sustainable Living and Eco-Friendly Choices',
        imageUrl: assest.H3,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '11 November, 2024',
        section: 'top',
        content: 'In todays world, sustainability is more important than ever.'
      },
      {
        id: 2,
        category: 'Technology',
        title: 'AI in Everyday Life',
        imageUrl: assest.H2,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '10 November, 2024',
        section: 'top',
        content: 'Artificial Intelligence (AI) is no longer just a buzzword or a ...',
      },
      {
        id: 3,
        category: 'Health',
        title: 'Mental Health in the Modern World',
        imageUrl: assest.H5,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '05 November, 2024',
        section: 'top',
        content: 'Mental health has become a critical issue in modern society...', // Article content
      },
      {
        id: 4,
        category: 'Health',
        title: 'Health and Wellness Trends for 2024',
        imageUrl: assest.H4,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '09 November, 2024',
        section: 'editorChoice',
        content: 'The modern world is evolving rapidly, bringing both tremendous...',
      },
      {
        id: 5,
        category: 'Technology',
        title: '3 Technology Basics You Should Be Reviewing Constantly',
        imageUrl: assest.H6,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '15 November, 2024',
        section: 'recent',
        content: 'In todays fast-paced, tech-driven world, keeping up with the basics...',
      },
      {
        id: 6,
        category: 'Education',
        title: 'The Future of Learning: EdTech and AI-Driven Education',
        imageUrl: assest.H7,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '14 November, 2024',
        section: 'recent',
        content: 'The education sector is undergoing a revolutionary transformation...',
      },
      {
        id: 7,
        category: 'Fashion',
        title: '2024 Fashion Trends: What’s Hot and What’s Not',
        imageUrl: assest.H8,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '10 November, 2024',
        section: 'editorChoice',
        content: 'The fashion world is constantly evolving, and 2024 is no exception...',
      },
      {
        id: 8,
        category: 'Lifestyle',
        title: 'Micro-Adventures: Exploring Locally on a Budget',
        imageUrl: assest.H9,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '12 November, 2024',
        section: 'editorChoice',
        content: 'In a world where travel costs continue to rise and schedules get busier...',
      },
      {
        id: 9,
        category: 'Finance',
        title: 'Personal finance tips for 2024: Managing money in the modern world',
        imageUrl: assest.H10,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '14 November, 2024',
        section: 'recent',
        content: 'As we step into 2024, managing personal finances has become more...',
      },
      {
        id: 10,
        category: 'Technology',
        title: 'Cybersecurity Essentials for Digital Safety',
        imageUrl: assest.H11,
        author: 'Admin',
        authorImage: assest.admin, // Author image
        date: '12 November, 2024',
        section: 'recent',
        content: 'In todays increasingly digital world, cybersecurity is no longer...',
      },
      
     
      // Add more articles as needed...
    ],
  });

  // Function to get articles by section (e.g., "top", "recent", etc.)
  const getArticlesBySection = (section) => {
    return articles.allArticles.filter((article) => article.section === section);
  };

  // Function to get all articles (including normal ones) without filtering by section
  const getAllArticles = () => {
    return articles.allArticles;
  };

  // Function to get a single article by its ID (to display the full article)
  const getArticleById = (id) => {
    return articles.allArticles.find((article) => article.id === id);
  };

  return (
    <ArticlesContext.Provider
      value={{
        articles,
        setArticles,  // Exposing the setArticles function to update the state
        getArticlesBySection,
        getAllArticles,
        getArticleById,
      }}
    >
      {children}
    </ArticlesContext.Provider>
  );
};

// Custom hook to use the ArticlesContext
export const useArticles = () => useContext(ArticlesContext);
