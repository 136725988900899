import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useArticles } from '../Contex/ArticlesContext';

function ArticleDetail() {
  const { id } = useParams();
  const { articles, setArticles } = useArticles(); // Grab articles and setArticles from context
  
  // State to hold the article content as HTML
  const [articleHtml, setArticleHtml] = useState('');
  const [likes, setLikes] = useState(0); // Initialize likes
  const [feedbackName, setFeedbackName] = useState('');
  const [feedbackContent, setFeedbackContent] = useState('');
  
  // Retrieve feedback from localStorage for this article
  const savedFeedback = JSON.parse(localStorage.getItem(`feedback-${id}`)) || [];
  const [submittedFeedback, setSubmittedFeedback] = useState(savedFeedback);

  // Find the article based on the ID from the URL
  const article = articles.allArticles.find((a) => a.id === parseInt(id));

  useEffect(() => {
    if (article) {
      // Fetch the HTML content for the article from the public folder
      fetch(`/articles/article${id}.html`)
        .then((response) => response.text())
        .then((htmlContent) => setArticleHtml(htmlContent))
        .catch((error) => console.error("Error loading article HTML:", error));

      // Get saved likes from localStorage or default to 0
      const savedLikes = JSON.parse(localStorage.getItem(`likes-${id}`)) || 0;
      setLikes(savedLikes);
    }
  }, [id, articles]);

  // Increment likes and update localStorage
  const handleLike = () => {
    const newLikes = likes + 1;
    setLikes(newLikes);
    localStorage.setItem(`likes-${id}`, JSON.stringify(newLikes));

    // Optionally, update the global state if you want it to reflect globally
    setArticles((prevArticles) => ({
      ...prevArticles,
      allArticles: prevArticles.allArticles.map((a) =>
        a.id === article.id ? { ...a, likes: newLikes } : a
      ),
    }));
  };

  // Handle feedback form submission
  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    if (feedbackName && feedbackContent) {
      const newFeedback = { name: feedbackName, content: feedbackContent };

      // Save feedback to state and localStorage
      const updatedFeedback = [...submittedFeedback, newFeedback];
      setSubmittedFeedback(updatedFeedback);
      localStorage.setItem(`feedback-${id}`, JSON.stringify(updatedFeedback));

      // Clear form fields
      setFeedbackName('');
      setFeedbackContent('');
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: article.title,
          text: article.content,
          url: window.location.href,
        })
        .then(() => console.log('Article shared successfully!'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser. Please copy the link manually.');
    }
  };

  if (!article) {
    return <div>Article not found</div>; // Return a "not found" message if no article is found
  }

  return (
    <div className="container my-4">
      {/* Article Image */}
      <img src={article.imageUrl} alt={article.title} className="img-fluid rounded mb-4" />
      
      {/* Article Title */}
      <h2>{article.title}</h2>
      {/* Display the article content using dangerouslySetInnerHTML */}
      <div className="article-content" dangerouslySetInnerHTML={{ __html: articleHtml }}></div>
      <div className="d-flex align-items-center mt-3">
        {article.authorImage && (
          <img
            src={article.authorImage}
            alt={article.author}
            className="rounded-circle me-2"
            width="100"
            height="100"
          />
        )}
        <p>Written by <strong>{article.author}</strong></p>
      </div>
      {/* Like button */}
      <button className="btn btn-primary mt-3 me-2" onClick={handleLike}>
        Like ({likes})
      </button>

      {/* Share button */}
      <button className="btn btn-secondary mt-3" onClick={handleShare}>
        Share
      </button>

      {/* Feedback Section */}
      <div className="mt-5">
        <h4>Leave Feedback</h4>
        <form onSubmit={handleFeedbackSubmit}>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Your Name"
              value={feedbackName}
              onChange={(e) => setFeedbackName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              rows="3"
              placeholder="Leave your feedback..."
              value={feedbackContent}
              onChange={(e) => setFeedbackContent(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" className="btn btn-secondary mt-2">Submit Feedback</button>
        </form>
      </div>

      {/* Display Submitted Feedback */}
      {submittedFeedback.length > 0 && (
        <div className="mt-4">
          <h4>Submitted Feedback</h4>
          <ul className="list-group">
            {submittedFeedback.map((feedback, index) => (
              <li key={index} className="list-group-item">
                <strong>{feedback.name}</strong>: {feedback.content}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ArticleDetail;
