import React from "react";
import "./Footer.css";
import { assest } from "../../assest/assest";

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="footer-content">
        <div className="footer-content-left">
          <img src={assest.expo} alt="FMS Education Logo" className="footer-logo" />
        
          
          <p>
          Empowering you with valuable insights, expert advice, and cutting-edge solutions—dedicated to inspiring excellence and growth.
          </p>
          <div className="footer-social-icons">
            <a href="https://www.facebook.com/Exposition.MIT?mibextid=kFxxJD" target="_blank" rel="noopener noreferrer">
              <img src={assest.facebook_icon} alt="Facebook" />
            </a>
            <a href="http://www.fiverr.com/s/8z6kgov" target="_blank" rel="noopener noreferrer">
              <img src={assest.twitter_icon} alt="Twitter" />
            </a>
            <a href="linkedin.com/in/shashika-prabath-950b26257" target="_blank" rel="noopener noreferrer">
              <img src={assest.linkedin_icon} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div className="footer-content-center">
          <h2>Links</h2>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#my-5">Blog</a></li>
            <li><a href="#container">Contact</a></li>
            <li><a href="#containera">About</a></li>
            
          </ul>
        </div>
        <div className="footer-content-right">
          <h2>Get in Touch</h2>
          <ul>
            
            <div className="contact">
              <a href="tel:+94717577400" className="contact1">
                <img src={assest.phone} alt="Phone" />
                +94714032013  <br /> +94785744896
              </a>
            </div>
            <div className="contact">
              <a href="mailto:exposition.edify@gmail.com" className="contact">
                <img src={assest.email} alt="Email" />
                shashikabio@gmail.com
              </a>
            </div>
          </ul>
        </div>
      </div>
      <hr />
      <p className="footer-copyright">© 2024 Shashika Prabath™. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
